import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const SimpleParagraph = memo(function SimpleParagraph({
  description,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      {description ? (
        <FadeInOutAnimation>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInOutAnimation>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 73.5rem;
  margin: 7.5rem auto;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    margin: 5.25rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  padding-right: 1.5rem;
`
